import React from 'react';
import { useSelector } from 'react-redux';
import { createForm } from 'rc-form';
import { doctorRegistration } from '../../../apis';
import { UserType } from '../../../helpers/constants';
import { navigateTo } from '../../../components/CrossPlatformLink';
import Helmet from 'react-helmet';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Layout from '../../../components/Layout';
import Table from '../../../components/Table';
import Cell from '../../../components/Table/cell';

export default createForm()(({ location, form }) => {
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const session = useSelector(state => state.session);
  const { validateFields, getFieldDecorator, getFieldError } = form;

  const confirm = () => {
    validateFields(async (error, values) => {
      if (!error) {
        register(values);
      }
    });
  }

  const handleChange = () => {
    setChecked(!checked);
  }

  const register = async (values) => {
    setLoading(true);
    var formData = new FormData();
    if (session.line) {
      formData.append('access_token', session.line.accessToken);
    }
    formData.append('phone', values.phone);
    // formData.append('th_first_name', values.th_first_name);
    // formData.append('th_last_name', values.th_last_name);
    // formData.append('en_first_name', values.en_first_name);
    // formData.append('en_last_name', values.en_last_name);
    formData.append('user_type', values.user_type);
    formData.append('email', values.email);
    formData.append('from_recruiter', checked);
    // formData.append('image', values.image.target.files[0]);
    // formData.append('license_number', values.license_number);
    // formData.append('education', values.education);
    // formData.append('bio', values.bio);
    // formData.append('affiliation', values.affiliation);
    // if (values.medical_license_image) {
    //   formData.append('medical_license_image', values.medical_license_image.target.files[0]);
    // }
    // if (values.sorpor_19) {
    //   formData.append('sorpor_19', values.sorpor_19.target.files[0]);
    // }
    try {
      await doctorRegistration(formData);
      if (!checked) {
        window.location.href = 'https://form.jotform.com/222192656719463';
      }
      else {
        alert('สมัครสมาชิกเรียบร้อยแล้ว');
        navigateTo(session.source, 'me.moronline://exit');
      }
    } catch (e) {
      const { detail } = e.message;
      alert(detail);
    }
    setLoading(false);
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ลงทะเบียนสำหรับบุคลากรทางการแพทย์ - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <h2>ลงทะเบียนสำหรับบุคลากรทางการแพทย์</h2>
        <Table>
          <TableBody>
            {/* <TableRow>
                  <Cell style={{ minWidth: 60 }}>ชื่อ</Cell>
                  <Cell>
                    {
                      getFieldDecorator('th_first_name', {
                        rules: [{ required: true }],
                      })(
                        <Input fullWidth error={getFieldError('th_first_name')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell style={{ minWidth: 60 }}>ชื่อ (ภาษาอังกฤษ)</Cell>
                  <Cell>
                    {
                      getFieldDecorator('en_first_name', {
                        rules: [{ required: true }],
                      })(
                        <Input fullWidth error={getFieldError('en_first_name')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>นามสกุล</Cell>
                  <Cell>
                    {
                      getFieldDecorator('th_last_name', {
                        rules: [{ required: true }],
                      })(
                        <Input fullWidth error={getFieldError('th_last_name')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>นามสกุล (ภาษาอังกฤษ)</Cell>
                  <Cell>
                    {
                      getFieldDecorator('en_last_name', {
                        rules: [{ required: true }],
                      })(
                        <Input fullWidth error={getFieldError('en_last_name')} />
                      )
                    }
                  </Cell>
                </TableRow> */}
            <TableRow>
              <Cell style={{ width: 140 }}>Email</Cell>
              <Cell>
                {
                  getFieldDecorator('email', {
                    rules: [{ required: true }],
                  })(
                    <Input type="email" fullWidth error={getFieldError('email')} />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell>เบอร์โทร</Cell>
              <Cell>
                {
                  getFieldDecorator('phone', {
                    rules: [{ required: true }],
                  })(
                    <Input fullWidth type="tel" error={getFieldError('phone')} />
                  )
                }
              </Cell>
            </TableRow>
            <TableRow>
              <Cell>เป็นบุคลากรด้านใด</Cell>
              <Cell>
                {
                  getFieldDecorator('user_type', {
                    rules: [{ required: true }],
                  })(
                    <Select
                      fullWidth
                      native
                      error={getFieldError('user_type')}
                    >
                      <option value="">เลือก</option>
                      <option value={UserType.DOCTOR}>หมอ</option>
                      <option value={UserType.PHARMACIST}>เภสัชกร</option>
                      <option value={UserType.NURSE}>พยาบาล</option>
                      <option value={UserType.PSYCHOLOGIST}>นักจิตวิทยา</option>
                      <option value={UserType.OCCUPATIONAL_THERAPIST}>นักกิจกรรมบำบัด</option>
                      <option value={UserType.PHYSICAL_THERAPIST}>นักกายภาพบำบัด</option>
                      <option value={UserType.OTHER}>บุคลากรด้านอื่นๆ</option>
                    </Select>
                  )
                }
              </Cell>
            </TableRow>
            {/* <TableRow>
                  <Cell>การศึกษา</Cell>
                  <Cell>
                    {
                      getFieldDecorator('education', {
                        rules: [{ required: true }],
                      })(
                        <TextField multiline fullWidth error={getFieldError('education')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>ประวัติ</Cell>
                  <Cell>
                    {
                      getFieldDecorator('bio', {
                        rules: [{ required: true }],
                      })(
                        <TextField multiline fullWidth error={getFieldError('bio')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>สังกัดโรงพยาบาล</Cell>
                  <Cell>
                    {
                      getFieldDecorator('affiliation', {
                        rules: [{ required: true }],
                      })(
                        <TextField fullWidth error={getFieldError('affiliation')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>เลขที่ใบประกอบวิชาชีพ</Cell>
                  <Cell>
                    {
                      getFieldDecorator('license_number', {
                        rules: [{ required: true }],
                      })(
                        <Input fullWidth error={getFieldError('license_number')} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>รูปใบประกอบวิชาชีพ</Cell>
                  <Cell>
                    {
                      getFieldDecorator('medical_license_image', {
                        getValueProps: getFileValueProps,
                        getValueFromEvent: getValueFromFileEvent,
                      })(
                        <input accept="image/*" type="file" style={{ width: '100%' }} />
                      )
                    }
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>รูป สป. 19</Cell>
                  <Cell>
                    {
                      getFieldDecorator('sorpor_19', {
                        getValueProps: getFileValueProps,
                        getValueFromEvent: getValueFromFileEvent,
                      })(
                        <input accept="image/*" type="file" style={{ width: '100%' }} />
                      )
                    }
                  </Cell>
                </TableRow> */}
            <TableRow>
              <Cell>คุณได้รับคำแนะนำมาจาก Agent หรือไม่?</Cell>
              <Cell>
                <input type="checkbox" checked={checked} onChange={handleChange} />
              </Cell>
            </TableRow>
          </TableBody>
        </Table>

        <Box mt={4}>
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={confirm}
          >
            ดำเนินการต่อ
          </Button>
        </Box>
      </Box >
    </Layout >
  );
});
